import React from 'react'

import TextField from '@mui/material/TextField'
import Title from './Title';

export default class MoonInput extends React.Component<
  {
    input: string
    onTaxInputChange: Function
    onOreInputChange: Function
    taxRate: number
  },
  {}
> {
  constructor (props) {
    super(props)
    this.handleOreChange = this.handleOreChange.bind(this)
    this.handleTaxChange = this.handleTaxChange.bind(this)
  }

  handleOreChange (event) {
    this.props.onOreInputChange(event.target.value)
  }

  handleTaxChange (event) {
    this.props.onTaxInputChange(event.target.value)
  }

  render () {
    return (
      <div>
        <Title>Ore Hold Contents</Title>
        <TextField
          autoFocus
          id='outlined-multiline-static'
          label='Ore Hold Contents'
          multiline
          rows={12}
          onChange={this.handleOreChange}
          placeholder='Paste ore hold contents here.'
        />
        <Title>Tax Rate</Title>
        <TextField
          value={this.props.taxRate}
          onChange={this.handleTaxChange}
          label='Tax Rate'
          type={'number'}
        />
      </div>
    )
  }
}
