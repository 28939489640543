import './App.scss'

import MoonCalculator from './MoonCalculator'
import Header from './Header'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

function App () {
  const theme = createTheme({
    palette: {
      mode: 'dark'
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <CssBaseline />
      <MoonCalculator />

      <Card sx={{ width: 375, margin: 3 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
            Questions? Problems? Praise?
          </Typography>
          <Typography sx={{ mb: 1.5 }} color='text.secondary'>
            Contact Ansirane Solette
          </Typography>
        </CardContent>
      </Card>
    </ThemeProvider>
  )
}

export default App
