import React from 'react'

import Title from './Title'

import price_time from './prices_date.js'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import ReactTimeAgo from 'react-time-ago'

export default class MoonOutput extends React.Component<
  {
    total: number
    displaySnackbar: Function
    taxRate: number
  },
  {}
> {
  copy = async () => {
    await navigator.clipboard.writeText(
      Math.round(this.props.total * (this.props.taxRate * 0.01)).toString()
    )
    let prettyTax = new Intl.NumberFormat().format(
      Math.round(this.props.total * (this.props.taxRate * 0.01))
    )
    this.props.displaySnackbar('Tax due copied! ' + prettyTax + ' ISK')
  }

  render () {
    return (
      <div>
        <Title>Estimated Taxed Value ({this.props.taxRate}%)</Title>
        <TextField
          id='taxed-output'
          label='Total Tax Due'
          variant='filled'
          disabled={true}
          sx={{ width: 1 }}
          value={Math.round(this.props.total * (this.props.taxRate * .01)).toLocaleString(
            navigator.language,
            {
              minimumFractionDigits: 0
            }
          )}
        />
        <Button
          variant='contained'
          sx={{ width: 1 }}
          onClick={this.copy}
          disabled={!this.props.total}
        >
          Copy tax to pay to Clipboard
        </Button>
        <Typography
          sx={{ fontSize: 20, mt: 2 }}
          component='h3'
          variant='h6'
          color='secondary'
          gutterBottom
        >
          <Button variant='outlined'>
            <Link href='https://goonfleet.com/index.php/topic/317801-kf-moon-mining-program/'>
              KF Moon Mining Program Rules
            </Link>
          </Button>
        </Typography>
        <Typography
          sx={{ fontSize: 15 }}
          component='h3'
          variant='h6'
          color='secondary'
          gutterBottom
        >
          <Button variant='outlined'>
            <Link href='https://eve.0x42.io/apps/kf_fracks.html'>
              Moon Frack Schedule
            </Link>
          </Button>
        </Typography>
        <Typography
          sx={{ fontSize: 15 }}
          component='h3'
          variant='h6'
          color='primary'
          gutterBottom
        >
          Prices as of:{' '}
          <ReactTimeAgo date={new Date(price_time)} locale='en-US' />
        </Typography>
        <Typography
          sx={{ fontSize: 15 }}
          component='h3'
          variant='h6'
          color='secondary'
          gutterBottom
        >
          These prices are updated hourly. Confirm on Goonpraisal to be sure of
          your price.
        </Typography>
      </div>
    )
  }
}
