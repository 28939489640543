import React from 'react'

import Paper from '@mui/material/Paper'
import Title from './Title'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import Button from '@mui/material/Button'

import MaterialRows from './MaterialRows'

export default class MaterialsOutput extends React.Component<
  {
    rows: Array<Object>
    materialsListText: string
  },
  {}
> {
  render () {
    const copyAndNav = async () => {
      await navigator.clipboard.writeText(this.props.materialsListText)
      var url = 'https://goonpraisal.com'
      window.open(url, '_blank')
    }

    return (
      <div>
        <Title>Estimated Materials Pricing</Title>
        <TableContainer component={Paper} sx={{ width: 1 }}>
          <Table sx={{ minWidth: 650 }} size='small' aria-label='simple table'>
            <MaterialRows rows={this.props.rows} />
          </Table>
        </TableContainer>
        <Button
          variant='outlined'
          onClick={copyAndNav}
          disabled={!this.props.materialsListText}
          sx={{ margin: 1 }}
        >
          Copy Materials Output to Clipboard & Open Goonpraisal
        </Button>
      </div>
    )
  }
}
