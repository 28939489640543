import React from 'react'

import MoonInput from './MoonInput'
import MoonOutput from './MoonOutput'
import MaterialsOutput from './MaterialsOutput'

import Prices from './prices.json'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert (
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const itemIds = require('./item_ids.json')
const materialLookupTable = require('./material_lookup_table.json')
const materialBonusTable = require('./material_bonus_table.json')

interface Props {}

const style = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper'
}

function createData (
  name: string,
  units: string,
  unitPrice: string,
  subtotal: string,
  url: string
) {
  return { name, units, unitPrice, subtotal, url }
}

export default class MoonCalculator extends React.Component<
  any,
  {
    input: string
    total: number
    oresList: string
    materialsList: Object
    materialsListText: string
    pricesListText: string
    rows: Array<Object>
    snackbarOpen: boolean
    snackbarMessage: string
    taxRate: number
  }
> {
  constructor (props: Props) {
    super(props)
    this.state = {
      input: '',
      total: 0,
      oresList: '',
      materialsListText: '',
      materialsList: {},
      pricesListText: '',
      rows: [],
      snackbarOpen: false,
      snackbarMessage: '',
      taxRate: 15
    }
    this.onOreInputChange = this.onOreInputChange.bind(this)
    this.onTaxInputChange = this.onTaxInputChange.bind(this)
    this.displaySnackbar = this.displaySnackbar.bind(this)
  }

  onTaxInputChange (input: string) {
    console.log('Got new tax rate', input)
    let newTaxRate = parseInt(input, 10)
    if (isNaN(newTaxRate)) {
      newTaxRate = 0
    }
    this.setState({ taxRate: newTaxRate }, this.calculate)
    
  }

  onOreInputChange (input: string) {
    console.log('Got new input!')
    console.log(input)
    this.setState({ input: input }, this.calculate)
  }

  calculate () {
    console.log(this.state)
    let lines = this.state.input.split('\n')
    console.log(lines)

    // Calculate how much of each ore is available
    // Take input which looks like:
    // Cobaltite 526,789 Material 3,125.53 m3 139,249,155.51 ISK
    // Bitumens 688,453 50,404.50ISK
    // Sylvite 253,680
    // Chop off everything past the first number
    // Cobaltite 526,789
    // Bitumens 688,453
    // Sylvite 253,680
    // Normalize
    // Cobaltite 526789
    // Bitumens 688453
    // Sylvite 253680

    let regEx = new RegExp(/(^[A-Za-z\s]+[\t ]?[\d,.?]+)/, 'g')
    let ores = []
    lines.filter(function (line) {
      console.log('====================')
      console.log(line)
      // Strip starting date if present (pasted from mining ledger)
      line = line.replace(/^[0-9.\s]+/g, '')

      // Remove word "Compressed" (not needed for this calculation)
      line = line.replace("Compressed ","")

      console.log(line)

      // Check if reg ex matches
      console.log(line.match(regEx))
      if (regEx.test(line)) {
        console.log(line.match(regEx))
        ores.push(line.match(regEx)[0].replace(/[,|.]/g, ''))
      }

      return regEx.test(line)
    })
    console.log('Got ores')
    console.log(ores)

    // Calculate the contents of the reprocessed ore
    // Calculate Reprocessed Outputs per input
    // Cobaltite 526789
    // Cobalt 210720
    // Bitumens 688453
    // Hydrocarbons 447525
    // Pyerite 41310000
    // Mexallon 2754000
    // Sylvite 253680
    // Evaporite Deposits 164905
    // Pyerite 10148000
    // Mexallon 1014800

    // Totals
    // Cobalt 210720
    // Hydrocarbons 447525
    // Pyerite 41310000
    // Mexallon 2754000
    // Evaporite Deposits 164905
    // Pyerite 10148000
    // Mexallon 1014800

    // * 0.893 Reprocessing Fee
    // Cobalt 188172
    // Hydrocarbons 399639
    // Pyerite 36889830
    // Mexallon 2459322
    // Evaporite Deposits 147260
    // Pyerite 9062164
    // Mexallon 906216

    // Output Total Refined
    // 3,031,826,096
    let oresList = ''
    let materialsList = {}
    let materialsListText = ''
    ores.forEach(ore => {
      let oreName = ore.match(new RegExp(/(^[A-Za-z\s]+)/, 'g'))[0]
      let oreCount = ore.match(new RegExp(/([0-9]+)/, 'g'))[0]
      console.log(oreName, oreCount)
      console.log(materialLookupTable[oreName])
      oresList = oresList + oreName + ' ' + oreCount + '\n'

      // First determine if the ore has a modifier
      // Get the first word
      console.log(materialBonusTable)
      let firstWord = oreName.split(' ')[0]
      let modifier = 1
      console.log('First word: ' + firstWord.toLowerCase())
      console.log(materialBonusTable)
      if (firstWord.toLowerCase() in materialBonusTable) {
        console.log('Got a modifier!')
        oreName = oreName.split(' ')
        oreName.shift()
        oreName = oreName.join(' ')
        oreName = oreName.replace(/\t/g, '')
        console.log('Base ore name: ' + oreName)
        modifier = materialBonusTable[firstWord.toLowerCase()]
      }

      oreName = oreName.trim()

      console.log('Get materials for ore: ' + oreName)
      // Calculate how many materials we will get from all our ore
      let materialsForOre = materialLookupTable[oreName]
      console.log(materialsForOre)
      if (materialsForOre) {
        Object.keys(materialsForOre).forEach(key => {
          let materialCount = materialsForOre[key]
          console.log(key, materialCount)
          if (!materialsList[key]) materialsList[key] = 0
          // 0.893 is the max reprocessing output
          materialsList[key] = Math.round(
            materialsList[key] +
              ((materialCount * modifier * oreCount) / 100) * 0.893
          )
        })
      }
    })

    // Generate materials text output
    console.log('Materials List: ', materialsList)
    let total = 0
    let pricesString = ''
    let newRows = []
    Object.keys(materialsList).forEach(key => {
      let materialCount = materialsList[key]
      materialsListText =
        materialsListText + key + ' ' + Math.round(materialCount) + '\n'

      let itemPrice = Prices[key.toLocaleLowerCase()]
      let materialPrice = Math.round(itemPrice * materialCount)
      console.log(
        'Price for ' +
          key +
          ': ' +
          itemPrice +
          ' Count: ' +
          Math.round(materialCount) +
          ' Total: ' +
          new Intl.NumberFormat().format(materialPrice)
      )
      total += materialPrice

      let itemId = itemIds[key.toLocaleLowerCase()]
      let url = 'https://images.evetech.net/types/' + itemId + '/icon?size=32'
      console.log(itemId, url)

      newRows.push(
        createData(
          key,
          new Intl.NumberFormat().format(materialCount),
          new Intl.NumberFormat().format(itemPrice),
          new Intl.NumberFormat().format(materialPrice),
          url
        )
      )

      pricesString +=
        key +
        ' | Count: ' +
        new Intl.NumberFormat().format(materialCount) +
        ' | Unit Price: ' +
        new Intl.NumberFormat().format(itemPrice) +
        ' ISK | Subtotal: ' +
        new Intl.NumberFormat().format(materialPrice) +
        ' ISK\n'
    })
    console.log('Total: ' + total)
    console.log(materialsListText)

    if (total < 1) return

    newRows.push(
      createData('Total', '-', '-', new Intl.NumberFormat().format(total), '')
    )

    // Automatically copy the tax due.
    navigator.clipboard.writeText(Math.round(total * (this.state.taxRate * .01)).toString())

    let prettyTax = new Intl.NumberFormat().format(Math.round(total * (this.state.taxRate * .01)))

    this.setState({
      oresList: oresList,
      materialsListText: materialsListText,
      total: total,
      pricesListText: pricesString,
      rows: newRows,
      snackbarOpen: true,
      snackbarMessage: 'Tax due copied! ' + prettyTax + ' ISK'
    })
  }

  displaySnackbar (snackbarMessage) {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: snackbarMessage
    })
  }

  render () {
    const handleClose = (
      event: React.SyntheticEvent | React.MouseEvent,
      reason?: string
    ) => {
      this.setState({
        snackbarOpen: false
      })
    }

    return (
      <div>
        <Grid container spacing={3} sx={{ my: 1 }}>
          <Grid item xs={12} md={4} lg={2}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 530,
                ml: 2
              }}
            >
              <List sx={style} component='nav' aria-label='mailbox folders'>
                <ListItem button divider>
                  <ListItemText primary='Paste the contents of your ore hold into the left field.' />
                </ListItem>
                <ListItem button divider>
                  <ListItemText primary='In game send ISK to KarmaFleet. Paste into the amount box.' />
                </ListItem>
                <ListItem button divider>
                  <ListItemText primary='Thank you for paying your taxes!' />
                </ListItem>
                <ListItem button>
                  <ListItemText primary='You can also use the Estimated Materials Pricing section to the right to do your own appraisal.' />
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={11} md={5} lg={2}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 530
              }}
            >
              <MoonInput
                input={this.state.input}
                onOreInputChange={this.onOreInputChange}
                onTaxInputChange={this.onTaxInputChange}
                taxRate={this.state.taxRate}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 530
              }}
            >
              <MoonOutput
                displaySnackbar={this.displaySnackbar}
                total={this.state.total}
                taxRate={this.state.taxRate}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 530
              }}
            >
              <MaterialsOutput
                rows={this.state.rows}
                materialsListText={this.state.materialsListText}
              />
            </Paper>
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.snackbarOpen}
          onClose={handleClose}
          autoHideDuration={5000}
        >
          <Alert
            onClose={handleClose}
            severity='success'
            sx={{ width: '100%' }}
          >
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    )
  }
}
