import React from 'react'

import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default class MaterialRows extends React.Component<
  { rows: Array<any> },
  {}
> {

  render () {
    if (this.props.rows.length) {
      let rows = this.props.rows
      return (
        <div>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Material</StyledTableCell>
              <StyledTableCell align='right'>Units</StyledTableCell>
              <StyledTableCell align='right'>Unit Price</StyledTableCell>
              <StyledTableCell align='right'>Subtotal</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <StyledTableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component='th' scope='row'>
                  <img alt='' className='icon' src={row.url} width={20} />
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align='right'>{row.units}</StyledTableCell>
                <StyledTableCell align='right'>{row.unitPrice}</StyledTableCell>
                <StyledTableCell align='right'>{row.subtotal}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </div>
      )
    } else {
      return false
    }
  }
}
